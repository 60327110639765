import { useGetCampaignRuns } from '@hooks/useCampaigns';
import { useLocale } from '@hooks/useLocale';
import { Button, Group, Menu, Radio, Stack, Text } from '@mantine/core';
import { CaretDown } from '@phosphor-icons/react';
import { useDashboardContext } from '@providers/DashboardProvider';
import { useProjectContext } from '@providers/ProjectProvider';
import { useQueryClient } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ButtonVariant } from '../../../mantineTheme';

const RunsFilterItem = ({
    timestamp,
    audienceName,
    userCount,
    checked,
    index,
}: {
    timestamp: string;
    audienceName: string;
    userCount: number;
    checked: boolean;
    index: number;
}) => {
    return (
        <Group spacing={'xs'}>
            <Radio checked={checked} />
            <Stack spacing={2}>
                <Text className="text-sm text-gray-700 font-medium">
                    {timestamp}{' '}
                    {index === 0 && (
                        <span className="text-gray-600">{`( Most Recent )`}</span>
                    )}
                </Text>
                <Text className="text-xs text-gray-600">
                    {audienceName} · {userCount} users
                </Text>
            </Stack>
        </Group>
    );
};

const AllRunsFilter = () => {
    const { t } = useLocale();
    const queryClient = useQueryClient();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const projectData = useProjectContext();
    const [selectedRun, setSelectedRun] = useState<{
        label: string;
        value: string;
    }>({
        label: t('campaign_analytics.all_runs'),
        value: queryParams.get('campaignUuid') ?? '',
    });
    const dashboardTemporaryFilters = useDashboardContext(
        (c) => c.dashboardTemporaryFilters,
    );
    const setDashboardTemporaryFilters = useDashboardContext(
        (c) => c.setDashboardTemporaryFilters,
    );

    const { data: campaignRuns } = useGetCampaignRuns({
        campaignId: queryParams.get('campaignUuid') ?? '',
        perPage: 10,
        currentPage: 1,
    });

    const handleFilterItemClick = (id: string, type: 'parent' | 'child') => {
        if (type === 'parent') {
            const newDashboardTemporaryFilters = {
                ...dashboardTemporaryFilters,
            };
            newDashboardTemporaryFilters.dimensions = [
                {
                    ...dashboardTemporaryFilters.dimensions[0],
                    values: [id],
                    target: {
                        fieldId:
                            projectData.projectData?.attributes?.analytics
                                .filters[0].parentCampaignIdColumName ?? '',
                        tableName:
                            projectData.projectData?.attributes?.analytics
                                .filters[0].tableName ?? '',
                    },
                },
            ];
            setDashboardTemporaryFilters(newDashboardTemporaryFilters);
        } else {
            const newDashboardTemporaryFilters = {
                ...dashboardTemporaryFilters,
            };
            newDashboardTemporaryFilters.dimensions = [
                {
                    ...dashboardTemporaryFilters.dimensions[0],
                    values: [id],
                    target: {
                        fieldId:
                            projectData.projectData?.attributes?.analytics
                                .filters[0].columnName ?? '',
                        tableName:
                            projectData.projectData?.attributes?.analytics
                                .filters[0].tableName ?? '',
                    },
                },
            ];
            setDashboardTemporaryFilters(newDashboardTemporaryFilters);
        }
        void queryClient.invalidateQueries(['savedChartResults']);
    };

    const menuItems = useMemo(() => {
        return campaignRuns?.data?.map((run) => ({
            label:
                (run.schedule?.executionTime &&
                    new Date(run.schedule?.executionTime).toISOString()) ||
                new Date().toISOString(),
            value: run.id ?? '',
        }));
    }, [campaignRuns]);

    // NOTE - Returning empty div to avoid rendering the filter if there are no runs and not break the flex layout
    if (campaignRuns?.data?.length === 0) return <div></div>;

    return (
        <Menu position="bottom-start">
            <Menu.Target>
                <Button
                    variant={ButtonVariant.OUTLINED}
                    rightIcon={<CaretDown size={14} />}
                >
                    {selectedRun.label}
                </Button>
            </Menu.Target>
            <Menu.Dropdown>
                <Menu.Item
                    onClick={() => {
                        handleFilterItemClick(
                            queryParams.get('campaignUuid') ?? '',
                            'parent',
                        );
                        setSelectedRun({
                            label: t('campaign_analytics.all_runs'),
                            value: queryParams.get('campaignUuid') ?? '',
                        });
                    }}
                >
                    <Group spacing={'xs'} className="border-b pb-2">
                        <Radio
                            checked={
                                selectedRun.value ===
                                queryParams.get('campaignUuid')
                            }
                        />
                        <Text>{t('campaign_analytics.all_runs')}</Text>
                    </Group>
                </Menu.Item>
                {menuItems?.map((item, index) => (
                    <Menu.Item
                        key={item.value}
                        onClick={() => {
                            handleFilterItemClick(item.value, 'child');
                            setSelectedRun(item);
                        }}
                    >
                        <RunsFilterItem
                            index={index}
                            checked={selectedRun.value === item.value}
                            timestamp={item.label}
                            audienceName={'run.audience_name'}
                            userCount={0}
                        />
                    </Menu.Item>
                ))}
            </Menu.Dropdown>
        </Menu>
    );
};

export default AllRunsFilter;
