import { type UseQueryOptions } from '@tanstack/react-query';

export type UseQueryFetchOptions = Pick<
    UseQueryOptions,
    | 'cacheTime'
    | 'staleTime'
    | 'refetchOnWindowFocus'
    | 'refetchOnMount'
    | 'refetchOnReconnect'
>;

export enum QueryKeys {
    'GET_AUDIENCE' = 'get-audience',
    'DELETE_CAMPAIGN' = 'delete-campaign',
    'GET_CAMPAIGNS' = 'get-campaigns',
    'TRIGGER_CAMPAIGN' = 'trigger-campaign',
    'TEMPLATE_VARIABLE' = 'template-variable',
    'ADD_CHANNEL' = 'add-channel',
    'UPDATE_CHANNELS' = 'update-channels',
    'USER_UPDATE' = 'user_update',
    'HEALTH' = 'health', //health
    'ORGANIZATION' = 'organization', //organization
    'ORGANIZATION_CREATE' = 'organization-create', //organization_create
    'USER' = 'user', //user
    'USER_ALLOWED_ORGANIZATIONS' = 'user-allowed-organizations', //user-allowed-organizations
    'USER_COMPLETE' = 'user-complete', //user_complete
    'USER_DELETE' = 'user-delete', //user_delete
    'LOGOUT' = 'logout', //logout
    'ACTIVE_PROJECT' = 'active-project', //activeProject
    'PROJECT' = 'project', //project
    'PROJECTS' = 'projects', //projects
    'VALIDATIONS' = 'validations', //validations
    'CREATE_AUDIENCE' = 'create-audience', //create-audience
    'UPDATE_AUDIENCE' = 'update-audience', //update-audience
    'ACTIVATE_AUDIENCE' = 'activate-audience', //activate-audience
    'AUDIENCE_GENERATE_SQL' = 'audience-generate-sql', //audience-generate-sql
    'AUDIENCE_COUNT_PAYLOAD' = 'audience-count-payload', //audience-count-payload
    'AUDIENCE_PREVIEW_ID' = 'audience-preview-id', //audience-preview-id
    'AUDIENCE_PREVIEW_PAYLOAD' = 'audience-preview-payload', //audience-preview-payload
    'BLOB_CREATE' = 'blob-create', //blob_create
    'BLOB_UPDATE' = 'blob-update', //blob_update
    'GET_CAMPAIGN_BY_ID' = 'get-campaign-by-id', //getCampaignById
    'CREATE_CAMPAIGN' = 'create-campaign', //createCampaign
    'UPDATE_CAMPAIGN' = 'update-campaign', //updateCampaign
    'EMAIL_STATUS' = 'email-status', //email_status
    'SEND_VERIFICATION_EMAIL' = 'send-verification-email', //send_verification_email
    'FLASH' = 'flash', //flash
    'VERIFY_ONE_TIME_PASSWORD' = 'verify-one-time-password', //verify_one_time_password
    'INTEGRATIONS' = 'integrations', //integrations
    'INTEGRATED_CHANNELS' = 'integrated-channels', //integratedChannels
    'INVITE_LINK' = 'invite-link', //invite_link
    'ONBOARDING_STATUS' = 'onboarding-status', //onboarding-status
    'ORGANIZATION_USERS' = 'organization-users', //organization_users
    'ORGANIZATION_USER' = 'organization-user', //organization_user
    'PASSWORD_RESET_LINK' = 'password-reset-link', //password_reset_link
    'SEND_PASSWORD_RESET_EMAIL' = 'send-password-reset-email', //send_password_reset_email
    'RESET_PASSWORD' = 'reset-password', //reset_password
    'PROJECT_CREATE' = 'project-create', //project_create
    'TEMPLATES' = 'templates', //templates
    'QUERY_RESULTS' = 'query-results', //queryResults
    'CHART_VERSION_RESULTS' = 'chart-version-results', //chartVersionResults
    'JOB' = 'job', //job
    'RELATION_SCHEMA' = 'relation-schema', //relation-schema
    'RELATIONS' = 'relations', //relations
    'SAVED_AUDIENCE' = 'saved-audience', //saved_audience
    'RESULTS' = 'results', //results
    'ACTIVE_SSH_KEY_PAIR' = 'active-ssh-key-pair', //activeSshKeypair
    'TABLES' = 'tables', //tables
    'DEFAULT_PROJECT' = 'default-project', //defaultProject,
    'CHANNELS' = 'channels',
    'PROJECT_ACCESS_USER' = 'project_access_users', //project_access_users
    'PROJECT_ACCESS_CREATE' = 'project_access_create', //project_access_create
    'ORGANIZATION_UPDATE' = 'organization_update', //organization_update
    'ORGANIZATION_USER_ONE' = 'organization_user_one',
    'USER_HAS_PASSWORD' = 'user-has-password',
    'USER_PASSWORD_UPDATE' = 'user_password_update',
    'PROJECT_UPDATE' = 'project_update',
    'CACHE_CONFIG' = 'cache-config',
    'PROVIDER_CONFIG' = 'provider-config',
    'AIBUILDER' = 'aibuilder',
    'ORGANIZATION_GROUPS' = 'organization_groups',
    'PROJECT_ACCESS_USER_WITH_LINK_INFO' = 'project_access_user_with_link_info',
    'CREATE_GROUP' = 'create_group',
    'AUDIENCE_SCHEDULER' = 'audience_scheduler',
    'FIRSTNAME' = 'firstName',
    'LASTNAME' = 'lastName',
    'EMAIL' = 'email',
    'ROLE' = 'role',
    'ORGANIZATION_USERS_DELETE' = 'organization_users_delete',
    'ORGANIZATION_MEMBERSHIP_ROLES' = 'organization_membership_roles',
    'GET_TEMPLATE' = 'get-template',
    'IMPORT_TEMPLATES_PROVIDER' = 'import-templates-provider',
    'MIGRATE_TEMPLATES_PROVIDER' = 'migrate-templates-provider',
    'GET_ALL_TEMPLATE' = 'get-all-templates',
    'TRIGGER_TEST_CAMPAIGN' = 'trigger-test-campaign',
    'GET_CUSTOM_METRICS' = 'get-custom-metrics',
    'CREATE_CUSTOM_METRIC' = 'create-custom-metric',
    'GET_CUSTOM_METRICS_BY_ID' = 'get-custom-metrics-by-id',
    'CREATE_TEMPLATE' = 'create-template',
    'UPDATE_TEMPLATE' = 'update-template',
    'WAREHOUSE_TABLES' = 'warehouse-tables',
    'EVENT_CONFIG' = 'event-config',
    'CREATE_RELATION_TABLE' = 'create-relation-table',
    'UPLOAD_FILE' = 'upload-file',
    'GET_COMMUNICATION_CHANNEL' = 'get-communication-channel',
    'UPDATE_TABLE_METADATA' = 'update-table-metadata',
    'CREATE_SUBSCRIPTION_CATEGORY' = 'create-subscription-category',
    'CREATE_SUBSCRIPTION_GROUP' = 'create-subscription-group',
    'GET_SUBSCRIPTION_GROUPS' = 'get-subscription-groups',
    'GET_DEFAULT_SUBSCRIPTION_GROUPS' = 'get-default-subscription-groups',
    'GET_SUBCRIPTION_GROUP_BY_ID' = 'get-subscription-group-by-id',
    'UPDATE_SUBSCRIPTION_GROUP' = 'update-subscription-group',
    'GET_SUBCRIPTION_CATEGORY_BY_ID' = 'get-subscription-category-by-id',
    'UPDATE_SUBCRIPTION_CATEGORY_BY_ID' = 'update-subscription-category-by-id',
    'DELETE_SUBSCRIPTION_CATEORY' = 'delete-subscription-category',
    'DELETE_SUBSCRIPTION_GROUP' = 'delete-subscription-group',
    'GET_ALL_SUBSCRIPTION_CATEGORY' = 'get-all-subscription-category',
    'UPDATE_PROFILE' = 'update-profile',
    'CREATE_PROFILE' = 'create-profile',
    'GET_PROFILE' = 'get-profile',
    'GET_PROFILES' = 'get-profiles',
    'GET_PROFILE_COUNT' = 'get-profile-count',
    'MULTIPLE_CACHE_CONFIG' = 'multiple-cache-config',
    'CREATE_DELIVERY_CONTROLS' = 'create-delivery-controls',
    'GET_DELIVERY_CONTROLS' = 'get-delivery-controls',
    'UPDATE_DELIVERY_CONTROLS' = 'update-delivery-controls',
    'UPDATE_TREE' = 'update-tree',
    'UNSUBSCRIBE_GROUP' = 'unsubscribe-group',
    'UPDATE_USER_SUBSCRIPTION_PREFERENCE' = 'update-user-subscription-preference',
    'GET_SUBSCRIPTION_PREFERENCE_FOR_CHANNEL' = 'get-subscirption-preference-for-channel',
    'UPDATE_GLOBAL_PREFERENCE' = 'update-global-preference',
    'GET_USER_SUBSCRIPTION_PREFERENCE' = 'get-user-subscription-preference',
    'GET_ALL_WHITELISTED_EVENTS' = 'get-all-whitelisted-events',
    'GET_JOURNEY_BLOCKS' = 'get-journey-blocks',
    'GET_JOURNEYS' = 'get-journeys',
    'CREATE_JOURNEY' = 'create-journey',
    'GET_ALL_SOURCES' = 'get-all-sources',
    'CREATE_EVENT' = 'create-event',
    'EVENT_COLUMNS' = 'event_column',
    'GET_EVENT' = 'get_event',
    'CREATE_EVENT_COLUMNS' = 'create-event-columns',
    'GET_ALL_ENTITY_KEYS' = 'get-all-entity-keys',
    'GET_EVENT_SOURCE_BY_ID' = 'get-event-source-by-id',
    'GET_ALL_EVENT_SOURCES' = 'get-all-event-sources',
    'CREATE_EVENT_SOURCE' = 'create-event-source',
    'CREATE_ENTITY_KEY' = 'create-entity-key',
    'DELETE_ENTITY_KEY' = 'delete-entity-key',
    'REFRESH_DIMENSIONS' = 'refresh-dimensions',
    'GET_UTM_KEYS' = 'get-utm-keys',
    'CREATE_UTM_KEYS' = 'create-utm-keys',
    'GET_JOURNEY_BY_ID' = 'get-journey-by-id',
    'UPDATE_JOURNEY' = 'update-journey',
    'GET_JOURNEY_NODE_PARAMS' = 'get-journey-node-params',
    'GET_JOURNEY_EVENTS' = 'GET_JOURNEY_EVENTS',
    'ACTIVATE_JOURNEY' = 'activate-journey',
    'DELETE_JOURNEY' = 'delete-journey',
    'UPDATE_EVENT_SOURCE' = 'update-event-source',
    'TEST_PROVIDER_INTEGRATION' = 'test-provider-integration',
    'STOP_CAMPAIGN' = 'stop-campaign',
    'GET_CAMPAIGN_RUNS' = 'get-campaign-runs',
    'UPDATE_CAMPAIGN_TAGS' = 'update-campaign-tags',
    'UPDATE_JOURNEY_TAGS' = 'update-journey-tags',
    'UPDATE_TEMPLATE_TAGS' = 'update-template-tags',
    'DELETE_CHANNEL_INTEGRATION' = 'delete-channel-integration',
    'DELETE_EVENT_SOURCE' = 'delete-event-source',
    'GET_LAST_RUN_STATUS' = 'get-last-run-status',
    'REFRESH_CAMPAIGN_ANALYTICS' = 'refresh-campaign-analytics',
    'GET_SYNCED_TEMPLATES' = 'get-synced-templates',
    'GET_SYNCED_TEMPLATE_BY_TEMPLATE_NAME' = 'get-synced-template-by-template-name',
    'SYNC_WHATSAPP_TEMPLATES' = 'sync-whatsapp-templates',
    'PUBLISH_CAMPAIGN' = 'publish-campaign',
    'UNPUBLISH_CAMPAIGN' = 'unpublish-campaign',
    'GENERATE_TABLE_DESCRIPTION' = 'generate-table-description',
    'GENERATE_COLUMN_DESCRIPTION' = 'generate-column-description',
    'GENERATE_VISUAL_AI_AUDIENCE' = 'generate-visual-ai-audience',
    'DUPLICATE_CAMPAIGN' = 'duplicate-campaign',
    'AUDIENCE_BREAKDOWN' = 'audience-breakdown',
    'AUDIENCE_REACHABILITY' = 'audience-reachability',
    'AUDIENCE_OVERLAP' = 'audience-overlap',
    'GENERATE_NODE_DESCRIPTION' = 'generate-node-description',
    'GENERATE_AI_AUDIENCE_DESCRIPTION' = 'generate-ai-audience-description',
    'GET_CAMPAIGN_ANALYTICS_OVERVIEW' = 'get-campaign-analytics-overview',
    'GET_USER_HISTORY' = 'get-user-history',
    'DEACTIVATE_JOURNEY' = 'deactivate-journey',
    'GET_JOURNEY_ANALYTICS' = 'get-journey-analytics',
    'PROJECT_UPDATE_SYNC_CONFIG' = 'project_update_sync_config',
    'PROJECT_UPDATE_REACHABILITY_CONFIG' = 'project_update_reachability_config',
    'REFRESH_TABLES' = 'refresh-tables',
    'PROJECT_USER_ALERT_PREFERENCES' = 'project_user_alert_preferences',
    'GET_PROFILE_SUGGESTIONS' = 'get-profile-suggestions',
    'INITIATE_BEEFREE' = 'initiate-befree',
}
