import { sortmentApi } from '@api/index';
import { getInitialCampaignPayload } from '@components/Campaigns/useCampaignInitialData';
import {
    CampaignScheduleType,
    CampaignType,
    type ApiCampaign,
    type ApiCampaignList,
    type ApiCampaignRuns,
    type ApiError,
    type ApiSuccessEmpty,
    type Campaign,
    type CampaignAnalyticsOverviewResponse,
    type CampaignCreateRequest,
    type CampaignList,
    type CampaignTagsUpdateRequest,
    type CampaignUpdateRequest,
    type CampaignWithAudienceDetails,
    type CommunicationChannel,
    type PublishCampaignRequest,
    type TestCampaignRequest,
    type TestCampaignRequestv1,
    type UtmKeyMapping,
} from '@lightdash/common';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { LIST_REFETCH_TIME } from '@utils/constants';
import { useHistory, useParams } from 'react-router-dom';
import { QueryKeys } from 'types/UseQuery';
import useNotify from './toaster/useNotify';
import { useLocale } from './useLocale';

// Fetch Campaign List
const getCampaignList = async (
    perPage: number,
    currentPage: number,
    query: string,
    projectId?: string,
) =>
    sortmentApi<ApiCampaignList>({
        url: `/projects/${projectId}/campaigns?perPage=${perPage}&currentPage=${currentPage}&${query}`,
        method: 'GET',
        body: undefined,
    });
interface UseCampaignsProps {
    perPage: number;
    currentPage: number;
    query?: string;
    polling: boolean;
}
export const useCampaigns = ({
    perPage,
    currentPage,
    query,
    polling = true,
}: UseCampaignsProps) => {
    const params = useParams<{ projectUuid?: string }>();
    const audiences = useQuery<ApiCampaignList, ApiError, CampaignList>({
        queryKey: [
            QueryKeys.GET_CAMPAIGNS,
            params,
            perPage,
            currentPage,
            query,
        ],
        refetchInterval: polling ? LIST_REFETCH_TIME : false,
        queryFn: () =>
            getCampaignList(
                perPage,
                currentPage,
                query || '',
                params?.projectUuid || '',
            ),
        enabled: !!params?.projectUuid,
        keepPreviousData: true,
    });

    return audiences;
};

// Fetch Campaign By Id
const getCampaignById = async (campaignId?: string, projectId?: string) =>
    sortmentApi<ApiCampaign>({
        url: `/projects/${projectId}/campaigns/${campaignId}`,
        method: 'GET',
        body: undefined,
    });

export const useGetCampaignById = (campaignId?: string) => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const data = useQuery<ApiCampaign, ApiError, CampaignWithAudienceDetails>({
        queryKey: [QueryKeys.GET_CAMPAIGN_BY_ID, campaignId],
        queryFn: () => getCampaignById(campaignId, projectUuid),
        enabled: !!campaignId && !!projectUuid,
        cacheTime: 0, // INFO - Disabling the cache here to prevent the latest data not available in case of draft edit mode
    });
    return data;
};

const getCampaignRuns = async (
    campaignId: string,
    perPage: number,
    currentPage: number,
    projectId?: string,
) =>
    sortmentApi<ApiCampaignRuns>({
        url: `/projects/${projectId}/campaigns/${campaignId}/runs?perPage=${perPage}&currentPage=${currentPage}`,
        method: 'GET',
        body: undefined,
    });

export const useGetCampaignRuns = ({
    campaignId,
    perPage,
    currentPage,
}: {
    campaignId: string;
    perPage: number;
    currentPage: number;
}) => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const data = useQuery<
        ApiCampaignRuns,
        ApiError,
        Pick<CampaignList, 'data' | 'paginate'>
    >({
        queryKey: [
            QueryKeys.GET_CAMPAIGN_RUNS,
            campaignId,
            perPage,
            currentPage,
        ],
        queryFn: () =>
            getCampaignRuns(campaignId, perPage, currentPage, projectUuid),
        enabled: !!campaignId && !!projectUuid,
    });
    return data;
};

// Create Campaign
const CreateCampaign = async (data: CampaignCreateRequest, projectId: string) =>
    sortmentApi<Campaign>({
        url: `/projects/${projectId}/campaigns`,
        method: 'POST',
        body: JSON.stringify(data),
    });

export const useCreateCampaign = () => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { showToastError } = useNotify();
    const queryClient = useQueryClient();
    const { t } = useLocale();
    const history = useHistory();

    const response = useMutation<Campaign, ApiError, CampaignCreateRequest>(
        (payload: CampaignCreateRequest) =>
            CreateCampaign(payload, projectUuid),
        {
            mutationKey: [QueryKeys.CREATE_CAMPAIGN],
            onSuccess: async (data) => {
                await queryClient.invalidateQueries([QueryKeys.GET_CAMPAIGNS]);
                history.push(
                    `/projects/${projectUuid}/campaigns/${data.id}/edit?channel=${data.channel}`,
                );
            },
            onError: (error) => {
                showToastError({
                    title: t('campaign.create.error'),
                    subtitle: error.error.message,
                });
            },
        },
    );
    return response;
};

// Update Campaign
const UpdateCampaign = async (
    data: CampaignUpdateRequest,
    projectId: string,
    campaignId: string,
) =>
    sortmentApi<ApiSuccessEmpty>({
        url: `/projects/${projectId}/campaigns/${campaignId}`,
        method: 'PUT',
        body: JSON.stringify(data),
    });

export const useUpdateCampaign = (
    campaignId: string,
    redirectOnSuccess: boolean = false,
) => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { showToastError } = useNotify();
    const history = useHistory();
    // const queryClient = useQueryClient();
    return useMutation<ApiSuccessEmpty, ApiError, CampaignUpdateRequest>(
        (payload: CampaignUpdateRequest) =>
            UpdateCampaign(payload, projectUuid, campaignId),
        {
            mutationKey: [QueryKeys.UPDATE_CAMPAIGN, campaignId, projectUuid],
            onSuccess: async () => {
                // showToastSuccess({
                //     title: 'Success! Your campaign is updated.',
                // });
                // void queryClient.invalidateQueries([
                //     QueryKeys.GET_CAMPAIGN_BY_ID,
                //     campaignId,
                // ]);
                // await queryClient.invalidateQueries([QueryKeys.GET_CAMPAIGNS]);
                if (redirectOnSuccess) {
                    history.push('/projects/' + projectUuid + '/campaigns');
                }
            },
            onError: (error) => {
                showToastError({
                    title: 'Error! Your campaign is not updated.',
                    subtitle: error.error.message,
                });
            },
        },
    );
};

const triggerCampaign = async (campaignId: string, projectUuid: string) =>
    sortmentApi<ApiSuccessEmpty>({
        url: `/projects/${projectUuid}/campaigns/${campaignId}/trigger`,
        method: 'POST',
        body: undefined,
    });

export const useTriggerCampaign = () => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { showToastSuccess, showToastError } = useNotify();
    const { t } = useLocale();
    return useMutation<ApiSuccessEmpty, ApiError, { campaignId: string }>(
        ({ campaignId }) => triggerCampaign(campaignId, projectUuid), // Removed extra parentheses
        {
            mutationKey: [QueryKeys.TRIGGER_CAMPAIGN],
            onSuccess: async () => {
                showToastSuccess({
                    title: t('campaign.trigger.success'),
                });
            },
            onError: (error) => {
                showToastError({
                    title: t('campaign.trigger.error'),
                    subtitle: error.error.message,
                });
            },
        },
    );
};

const stopCampaign = async (campaignId: string, projectUuid: string) =>
    sortmentApi<ApiSuccessEmpty>({
        url: `/projects/${projectUuid}/campaigns/${campaignId}/cancel`,
        method: 'POST',
        body: undefined,
    });

export const useStopCampaign = () => {
    const queryClient = useQueryClient();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { showToastSuccess, showToastError } = useNotify();
    const { t } = useLocale();
    return useMutation<ApiSuccessEmpty, ApiError, { campaignId: string }>(
        ({ campaignId }) => stopCampaign(campaignId, projectUuid),
        {
            mutationKey: [QueryKeys.STOP_CAMPAIGN],
            onSuccess: async () => {
                await queryClient.invalidateQueries([QueryKeys.GET_CAMPAIGNS]);
                showToastSuccess({
                    title: t('campaign.stop.success'),
                });
            },
            onError: (error) => {
                showToastError({
                    title: t('campaign.stop.error'),
                    subtitle: error.error.message,
                });
            },
        },
    );
};

const deleteCampaign = async (campaignId: string, projectUuid: string) =>
    sortmentApi<ApiSuccessEmpty>({
        url: `/projects/${projectUuid}/campaigns/${campaignId}`,
        method: 'DELETE',
        body: undefined,
    });

export const useDeleteCampaign = () => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { showToastSuccess, showToastError } = useNotify();
    const { t } = useLocale();
    return useMutation<ApiSuccessEmpty, ApiError, { campaignId: string }>(
        ({ campaignId }) => deleteCampaign(campaignId, projectUuid), // Removed extra parentheses
        {
            mutationKey: [QueryKeys.DELETE_CAMPAIGN],
            onSuccess: async () => {
                showToastSuccess({
                    title: t('campaign.delete.success'),
                });
            },
            onError: (error) => {
                showToastError({
                    title: t('campaign.delete.error'),
                    subtitle: error.error.message,
                });
            },
        },
    );
};

const triggerTestCampaignOldFlow = async (
    projectId: string,
    providerId: string,
    integrationId: string,
    payload: TestCampaignRequestv1,
) =>
    sortmentApi<ApiSuccessEmpty>({
        url: `/projects/${projectId}/communications/providers/${providerId}/integrations/${integrationId}/test`,
        method: 'POST',
        body: JSON.stringify(payload),
    });

export const useTestCampaignOldFlow = (
    providerId: string,
    integrationId: string,
) => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { showToastSuccess, showToastError } = useNotify();
    const { t } = useLocale();
    return useMutation<
        ApiSuccessEmpty,
        ApiError,
        { payload: TestCampaignRequestv1 }
    >(
        ({ payload }) =>
            triggerTestCampaignOldFlow(
                projectUuid,
                providerId,
                integrationId,
                payload,
            ),
        {
            mutationKey: [QueryKeys.TRIGGER_TEST_CAMPAIGN],
            onSuccess: async () => {
                showToastSuccess({
                    title: t(
                        'campaigns_builder.send_test_communication_success',
                    ),
                });
            },
            onError: (error) => {
                showToastError({
                    title: t(
                        'campaigns_builder.send_test_communication_failed',
                    ),
                    subtitle: error.error.message,
                });
            },
        },
    );
};

const triggerTestCampaign = async (
    projectId: string,
    campaignId: string,
    payload: TestCampaignRequest,
) =>
    sortmentApi<ApiSuccessEmpty>({
        url: `/projects/${projectId}/campaigns/${campaignId}/test`,
        method: 'POST',
        body: JSON.stringify(payload),
    });

export const useTestCampaign = () => {
    const { projectUuid, campaignUuid } = useParams<{
        projectUuid: string;
        campaignUuid: string;
    }>();
    const { showToastSuccess, showToastError } = useNotify();
    const { t } = useLocale();
    return useMutation<
        ApiSuccessEmpty,
        ApiError,
        { payload: TestCampaignRequest }
    >(
        ({ payload }) =>
            triggerTestCampaign(projectUuid, campaignUuid, payload),
        {
            mutationKey: [QueryKeys.TRIGGER_TEST_CAMPAIGN],
            onSuccess: async () => {
                showToastSuccess({
                    title: t(
                        'campaigns_builder.send_test_communication_success',
                    ),
                });
            },
            onError: (error) => {
                showToastError({
                    title: t(
                        'campaigns_builder.send_test_communication_failed',
                    ),
                    subtitle: error.error.message,
                });
            },
        },
    );
};

const getUtmKeys = ({ projectUuid }: { projectUuid: string }) => {
    return sortmentApi<UtmKeyMapping[]>({
        url: `/projects/${projectUuid}/communications/utm-keys`,
        method: 'GET',
        body: undefined,
    });
};

export const useGetUtmKeys = () => {
    const { projectUuid } = useParams<{ projectUuid: string }>();

    return useQuery<UtmKeyMapping[], ApiError>({
        queryKey: [QueryKeys.GET_UTM_KEYS, projectUuid],
        queryFn: () => getUtmKeys({ projectUuid }),
        enabled: Boolean(projectUuid),
    });
};

const createUtmKey = ({
    projectUuid,
    body,
}: {
    projectUuid: string;
    body: UtmKeyMapping;
}) => {
    return sortmentApi<UtmKeyMapping[]>({
        url: `/projects/${projectUuid}/communications/utm-keys`,
        method: 'POST',
        body: JSON.stringify(body),
    });
};

export const useCreateUtmKey = () => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const queryClient = useQueryClient();

    return useMutation<UtmKeyMapping[], ApiError, UtmKeyMapping>({
        mutationKey: [QueryKeys.CREATE_UTM_KEYS, projectUuid],
        mutationFn: (body: UtmKeyMapping) =>
            createUtmKey({ projectUuid, body }),
        onSuccess: async () => {
            await queryClient.invalidateQueries([
                QueryKeys.GET_UTM_KEYS,
                projectUuid,
            ]);
        },
    });
};

const updateCampaignTags = ({
    projectUuid,
    campaignId,
    tags,
}: {
    projectUuid: string;
    campaignId: string;
    tags: CampaignTagsUpdateRequest;
}) => {
    return sortmentApi<ApiSuccessEmpty>({
        url: `/projects/${projectUuid}/campaigns/${campaignId}/tags`,
        method: 'PUT',
        body: JSON.stringify(tags),
    });
};

export const useUpdateCampaignTags = ({
    campaignId,
}: {
    campaignId: string;
}) => {
    const { projectUuid } = useParams<{ projectUuid: string }>();

    return useMutation<ApiSuccessEmpty, ApiError, CampaignTagsUpdateRequest>({
        mutationKey: [QueryKeys.UPDATE_CAMPAIGN_TAGS, projectUuid],
        mutationFn: (tags: CampaignTagsUpdateRequest) =>
            updateCampaignTags({ projectUuid, campaignId, tags }),
    });
};

const publishCampaign = async (
    projectId: string,
    body: PublishCampaignRequest,
) =>
    sortmentApi<ApiSuccessEmpty>({
        url: `/projects/${projectId}/campaigns/publish`,
        method: 'POST',
        body: JSON.stringify(body),
    });

export const usePublishCampaign = () => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { showToastSuccess } = useNotify();
    const { t } = useLocale();
    const queryClient = useQueryClient();

    return useMutation<ApiSuccessEmpty, ApiError, PublishCampaignRequest>({
        mutationKey: [QueryKeys.PUBLISH_CAMPAIGN, projectUuid],
        mutationFn: (body) => publishCampaign(projectUuid, body),
        onSuccess: async () => {
            showToastSuccess({
                title: t('campaign.publish.success'),
            });
            void queryClient.invalidateQueries([QueryKeys.GET_CAMPAIGNS]);
        },
    });
};

export const useDuplicateCampaign = (data: {
    id: string;
    name: string;
    channel: CommunicationChannel;
    description?: string;
}) => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const queryClient = useQueryClient();
    const history = useHistory();

    return useMutation<Campaign, ApiError>({
        mutationKey: [QueryKeys.DUPLICATE_CAMPAIGN, projectUuid],
        mutationFn: () =>
            CreateCampaign(
                {
                    name: data.name + ' (Copy)',
                    description: '',
                    channel: data.channel as CommunicationChannel,
                },
                projectUuid,
            ),
        onSuccess: async (response: Campaign) => {
            const campaignData = await getCampaignById(data.id, projectUuid);
            if (!campaignData) return;
            const campaignPayload = getInitialCampaignPayload(
                campaignData as unknown as Campaign,
            );
            const { name, description, ...restPayload } = campaignPayload;

            restPayload.schedule = {
                type: CampaignScheduleType.MANUAL,
                executionTime: null,
            };
            restPayload.type = CampaignType.ONE_TIME;

            await UpdateCampaign(restPayload, projectUuid, response.id);
            await queryClient.invalidateQueries([QueryKeys.GET_CAMPAIGNS]);
            history.push(
                `/projects/${projectUuid}/campaigns/${response.id}/edit?channel=${response.channel}`,
            );
        },
    });
};

const getCampaignAnalyticsOverview = async (
    campaignId: string,
    projectId: string,
) =>
    sortmentApi<CampaignAnalyticsOverviewResponse>({
        url: `/projects/${projectId}/campaigns/${campaignId}/analytics-overview`,
        method: 'GET',
        body: undefined,
    });

export const useGetCampaignAnalyticsOverview = (
    campaignId: string,
    enabled: boolean,
) => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    return useQuery<CampaignAnalyticsOverviewResponse, ApiError>({
        queryKey: [QueryKeys.GET_CAMPAIGN_ANALYTICS_OVERVIEW, campaignId],
        queryFn: () => getCampaignAnalyticsOverview(campaignId, projectUuid),
        enabled,
        retry: false,
    });
};

const unpublishCampaign = async (
    projectId: string,
    body: PublishCampaignRequest,
) =>
    sortmentApi<ApiSuccessEmpty>({
        url: `/projects/${projectId}/campaigns/unpublish`,
        method: 'POST',
        body: JSON.stringify(body),
    });

export const useUnpublishCampaign = () => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { showToastSuccess } = useNotify();
    const { t } = useLocale();
    const queryClient = useQueryClient();

    return useMutation<ApiSuccessEmpty, ApiError, PublishCampaignRequest>({
        mutationKey: [QueryKeys.UNPUBLISH_CAMPAIGN, projectUuid],
        mutationFn: (body) => unpublishCampaign(projectUuid, body),
        onSuccess: async () => {
            showToastSuccess({
                title: t('campaign.publish.success'),
            });
            void queryClient.invalidateQueries([QueryKeys.GET_CAMPAIGNS]);
        },
    });
};
