import {
    type ContentMappings,
    type ContentMappingSection,
    type SenderDetails,
} from './campaign';
import {
    type CommunicationChannel,
    type Name,
    type TestDestination,
} from './communications';

export enum TemplateSource {
    SORTMENT = 'SORTMENT',
    SENDGRID = 'SENDGRID',
    FACEBOOK = 'FACEBOOK',
}

export type BeeFreeAuthResponse = {
    access_token: string;
    v2: boolean;
};

export type Template = {
    id: string;
    organizationId: string;
    projectId: string;
    name: string;
    description: string;
    clonedFromId: string | null;
    createdBy: string;
    createdAt: Date;
    updatedBy: string;
    updatedAt: Date;
    contents: TemplateContent[];
    channel: CommunicationChannel;
    imported: boolean;
    source: TemplateSource;
    consideredInLibrary: boolean;
    metadata: {
        [key: string]: string;
    };
    tags: string[] | undefined;
};

export type TemplateContent = {
    templateId: string;
    version: number;
    versionName: string | null;
    content: TemplateContentDetails;
    variables: TemplateVariableDetails;
    parsedVariables: ParsedVariable[] | null;
    previewUrl: string | null;
    createdAt: Date;
    createdBy: string;
    updatedAt: Date;
    updatedBy: string;
};

export type ParsedVariable = {
    key: string;
    type: string;
    value: string;
};

export type TemplateContentDetails =
    | EmailTemplateContentDetails
    | SmsTemplateContentDetails
    | WhatsAppTemplateContentDetails;

export type EmailTemplateContentDetails = {
    subject: string;
    html: string;
    json?: {
        [key: string]: any;
    };
};

export type TemplateTagsUpdateRequest = {
    tags: string[];
};

export type SmsTemplateContentDetails = {
    body: string;
    dltTemplateId?: string;
    unicode?: boolean;
    flash?: boolean;
};

export enum WAHeaderType {
    IMAGE = 'image',
    VIDEO = 'video',
    TEXT = 'text',
    FILE = 'file',
}

export enum WAButtonType {
    URL = 'url',
    QUICK_REPLY = 'quick_reply',
    COPY = 'copy_url',
    PHONE = 'phone',
}

export type WAHeader = {
    type: WAHeaderType;
    text?: string;
    url?: string;
    sample?: Array<string>;
};
export type WABody = {
    text: string;
    sample?: Array<string>;
};
export type WAFooter = {
    text: string;
};
export type WAButton = {
    type?: string;
    text?: string;
    title?: string;
    id?: string;
    is_dynamic?: boolean;
    domain?: string;
    url?: string;
    index?: number;
    phone?: string;
    value?: string;
};
export type WhatsAppTemplateContentDetails = {
    header: WAHeader;
    body: WABody;
    footer: WAFooter;
    buttons: Array<WAButton>;
    language?: string;
};

export type ExternalTemplate = {
    name: string;
    integrationId: string;
    customName: string;
    category: string;
    providerName: string;
    languages: Array<WhatsAppExternalTemplateContentDetails>;
    variables?: TemplateVariableDetails;
};

export type SyncExternalTemplate = {
    integrationId: string;
    providerId: string;
};

export type WhatsAppExternalTemplateContentDetails = {
    language: string;
    content: WhatsAppTemplateContentDetails;
    status: string;
    templateId: string;
};
export type ReducedTemplate = Pick<
    Template,
    | 'id'
    | 'name'
    | 'description'
    | 'createdAt'
    | 'updatedAt'
    | 'channel'
    | 'tags'
> & {
    content: TemplateContentDetails;
    createdBy: Name;
    updatedBy: Name;
    previewUrl?: string;
};

export type Pagination = {
    total?: number;
    lastPage?: number;
    currentPage: number;
    perPage: number;
    from: number;
    to: number;
};

export type TemplateList = {
    data: ReducedTemplate[] | null;
};

export type TemplateListWithPagination = TemplateList & {
    pagination: Pagination;
};

export type CreateTemplateRequest = Required<
    Pick<
        Template,
        'name' | 'description' | 'channel' | 'consideredInLibrary' | 'tags'
    > &
        Pick<TemplateContent, 'content' | 'previewUrl'>
>;

export type UpdateTemplateRequest = Partial<
    Pick<Template, 'name' | 'description'> & Pick<TemplateContent, 'content'>
>;

export type Paginate = {
    perPage: number;
    currentPage: number;
};

export type SearchTemplateFromIntegrationRequest = {
    providerId: string;
    integrationId: string;
    templateIds: string[];
};

export type ImportTemplateFromIntegrationRequest =
    SearchTemplateFromIntegrationRequest & {
        addToLibrary: boolean;
    };

export type Sorting = {
    field: string;
    direction: 'asc' | 'desc';
};
export type WaVariableDetails = {
    [ContentMappingSection.HEADER]?: string[];
    [ContentMappingSection.BODY]?: string[];
    [ContentMappingSection.BUTTONS]?: string[];
};
export type SMSVariableDetails = {
    [ContentMappingSection.BODY]?: string[];
};
export type EmailVariableDetails = {
    [ContentMappingSection.SUBJECT]?: string[];
    [ContentMappingSection.BODY]?: string[];
};
export type TemplateVariableDetails = WaVariableDetails &
    SMSVariableDetails &
    EmailVariableDetails;

export type ProviderTemplateDetails = {
    id: string;
    name: string;
    description: string | undefined;
    updatedAt?: string;
};

export type EmailTemplateDetails = Pick<Template, 'name'>;
// Need to put things specific to fyno since these external templates are fetched from there only.
export type WATemplateDetails = Pick<Template, 'id'> & {
    integrationId: string;
};

// TODO - Remove this function once we honor the new test flow in journeys
export type TestCampaignRequestv1 = {
    to: TestDestination;
    channel: CommunicationChannel;
    templateContent?: TemplateContentDetails;
    templateDetails: EmailTemplateDetails | WATemplateDetails;
    mappings: ContentMappings;
    senderDetails?: SenderDetails;
};

export type TestCampaignRequest = {
    to: TestDestination;
};

export type GetAllProviderTemplateRequest = {
    templateIds: string[];
};

export type GetAllProviderTemplateResponse = {
    templates: ProviderTemplateDetails[];
};

export type FileUploadResponse = {
    url: string;
};

export enum TemplateVariables {
    UNSUBSCRIBE = 'sortment_unsub_url',
}

export enum DefaultWhatsappHeaderVariables {
    IMAGE = 'https://storage.googleapis.com/image_upload_sortment_stg/4cf4e13fdf149d4572f8ffab829ae455aab0500dff9eeba09c41741ebe3eec51',
    VIDEO = 'https://fynodev.s3.ap-south-1.amazonaws.com/uploads/Sample_MP4_480_1_5MG-1726738980574-76224563.mp4',
}

export const DefaultWhatsappHeaderVariablesMap = {
    [WAHeaderType.TEXT]: '',
    [WAHeaderType.IMAGE]: DefaultWhatsappHeaderVariables.IMAGE,
    [WAHeaderType.VIDEO]: DefaultWhatsappHeaderVariables.VIDEO,
    [WAHeaderType.FILE]: '',
};

export const WhatsappHeaderVariable = 'url';
