import { sortmentApi } from '@api/index';
import {
    type ApiError,
    type ApiSqlQueryResults,
    type AudiencePreviewPayload,
} from '@lightdash/common';
import { useRelationContext } from '@providers/RelationProvider';
import { useMutation } from '@tanstack/react-query';
import {
    AUDIENCE_PREVIEW_BY_ID_LIMIT,
    AUDIENCE_PREVIEW_LIMIT,
} from '@utils/constants';
import { useParams } from 'react-router-dom';
import { QueryKeys } from 'types/UseQuery';
import useNotify from './toaster/useNotify';
import { useLocale } from './useLocale';
import useQueryError from './useQueryError';

interface AudiencePreviewByIdParams {
    relationId: string;
    audienceId: string;
    perPage?: number;
    currentPage?: number;
    data: AudiencePreviewPayload;
}

const getPreviewDataByAudienceId = async ({
    projectId,
    relationId,
    audienceId,
    perPage,
    currentPage,
    data,
}: AudiencePreviewByIdParams & { projectId: string }) =>
    sortmentApi<ApiSqlQueryResults>({
        url: `/projects/${projectId}/relations/${relationId}/audiences/${audienceId}/preview?perPage=${perPage}&currentPage=${currentPage}`,
        method: 'POST',
        body: JSON.stringify(data),
    });

export const useAudiencePreviewById = () => {
    const { projectUuid: projectId } = useParams<{ projectUuid: string }>();
    const { activeRelationUuid: relationId } = useRelationContext();

    const setErrorResponse = useQueryError();

    return useMutation<
        ApiSqlQueryResults,
        ApiError,
        {
            fields: string[];
            audienceId: string;
        }
    >(
        ({ fields, audienceId }) =>
            getPreviewDataByAudienceId({
                projectId,
                relationId,
                audienceId,
                perPage: AUDIENCE_PREVIEW_BY_ID_LIMIT,
                currentPage: 0,
                data: {
                    limit: AUDIENCE_PREVIEW_BY_ID_LIMIT,
                    dimensions: fields,
                    metrics: [],
                },
            }),
        {
            mutationKey: [QueryKeys.AUDIENCE_PREVIEW_ID, projectId, relationId],
            onError: (result) => setErrorResponse(result),
        },
    );
};

const getPreviewDataByPayload = async (
    projectId: string,
    relationId: string,
    data: AudiencePreviewPayload,
) =>
    sortmentApi<ApiSqlQueryResults>({
        url: `/projects/${projectId}/relations/${relationId}/audiences/preview`,
        method: 'POST',
        body: JSON.stringify(data),
    });

export const useAudiencePreviewByPayload = () => {
    const { showToastError } = useNotify();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { t } = useLocale();

    return useMutation<
        ApiSqlQueryResults,
        ApiError,
        { data: AudiencePreviewPayload; relationUuid: string; fields: string[] }
    >(
        ({ data, relationUuid, fields }) =>
            getPreviewDataByPayload(projectUuid, relationUuid, {
                limit: AUDIENCE_PREVIEW_LIMIT,
                ...data,
                dimensions: fields,
                metrics: [],
            }),
        {
            mutationKey: [QueryKeys.AUDIENCE_PREVIEW_PAYLOAD],
            onError: (error) => {
                showToastError({
                    title: t('use_audience_preview.error_audience_preview'),
                    subtitle: error.error.message,
                });
            },
        },
    );
};
