import { subject } from '@casl/ability';
import {
    getButtonHighlightClassName,
    modifyFieldsWithSelectionStatus,
} from '@components/Audience/Filters/FieldListItem/utils';
import { useFieldsWithSuggestions } from '@components/Audience/Filters/FiltersCard/useFieldsWithSuggestions';
import { type FieldWithSuggestions } from '@components/Audience/Filters/FiltersProvider';
import { useAbilityContext } from '@components/common/Authorization';
import ManagerBuilderContainer from '@components/common/BuilderContainer/ManagerBuilderContainer';
import SuboptimalState from '@components/common/SuboptimalState/SuboptimalState';
import { isProjectSetUpCompleted } from '@components/ProjectConnection/utils';
import SetupPage from '@components/SetupPage';
import { useAudienceCountByPayload } from '@hooks/useAudience';
import { useLocale } from '@hooks/useLocale';
import {
    useCreateProfile,
    useGetProfileConfig,
    useGetProfiles,
    useGetProfileSuggestions,
    useUpdateProfile,
} from '@hooks/useProfile';
import {
    ConditionalOperator,
    DimensionType,
    getItemId,
    JoinType,
} from '@lightdash/common';
import { Box, Button } from '@mantine/core';
import { useDebouncedState, useDisclosure } from '@mantine/hooks';
import { Sparkle, Spiral, SquareSplitHorizontal } from '@phosphor-icons/react';
import { useApp } from '@providers/AppProvider';
import { useProjectContext } from '@providers/ProjectProvider';
import { useRelationContext } from '@providers/RelationProvider';
import { SEARCH_INPUT_DEBOUNCE_TIME } from '@utils/constants';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ButtonVariant } from '../../../mantineTheme';
import ProfileTable from '../Table/ProfileTable';
import {
    formatProfileData,
    getFilterForProfileSearch,
    toFieldsWithSuggestions,
    updateProfilePayload,
} from '../utils';
import ProfileInitialCreateState from './ProfileInitialCreateState';
import ProfileManagerPropertySelect from './ProfileManagerPropertySelect';
import ProfileTitle from './ProfileTitle';

const PROFILE_PAGE_SIZE = 10;

const ProfileBuilderContainer: React.FC<{}> = ({}) => {
    const { t } = useLocale();

    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { activeRelationUuid, getTableRelation, activeRelation } =
        useRelationContext();
    const [totalCount, setTotalCount] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchString, setSearchString] = useDebouncedState<string>(
        '',
        SEARCH_INPUT_DEBOUNCE_TIME,
    );
    const [opened, { open, close }] = useDisclosure(false);
    const { projectData: activeProject } = useProjectContext();
    const {
        data: config,
        isFetching: isInitialLoading,
        isSuccess,
    } = useGetProfileConfig(projectUuid);
    const { user } = useApp();
    const ability = useAbilityContext();

    const canManageProfiles = ability.can(
        'manage',
        subject('Profile', {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );
    const {
        mutate: createProfile,
        isLoading: createLoading,
        isSuccess: creationSuccess,
    } = useCreateProfile(projectUuid);
    const {
        mutate: updateProfile,
        isLoading: updateLoading,
        isSuccess: updationSuccess,
    } = useUpdateProfile(projectUuid);
    const { mutate: updateProfileWithAI, isLoading: updateWithAILoading } =
        useGetProfileSuggestions(projectUuid);
    const fieldsWithSuggestions = useFieldsWithSuggestions({
        relationData: activeRelation,
        queryResults: undefined,
        additionalMetrics: undefined,
        tableCalculations: undefined,
        customDimensions: undefined,
    });
    const userIdColumn = useMemo(() => {
        const baseTableID = activeRelation?.baseTable;
        if (!baseTableID) return '';
        const baseTable = activeRelation?.tables[baseTableID];
        return `${baseTableID}_${baseTable?.primaryKey}`;
    }, [activeRelation]);
    const getConditionalOperator = useMemo(() => {
        const fieldType = fieldsWithSuggestions[userIdColumn]?.type;
        switch (fieldType) {
            case DimensionType.STRING:
                return ConditionalOperator.INCLUDE;
            default:
                return ConditionalOperator.EQUALS;
        }
    }, [userIdColumn, fieldsWithSuggestions]);
    const getProfileData = useMemo(() => {
        return {
            projectUuid,
            data:
                searchString.length > 0
                    ? {
                          filters: getFilterForProfileSearch({
                              primaryKey: userIdColumn,
                              profileId: searchString,
                              operator: getConditionalOperator,
                              fieldType:
                                  fieldsWithSuggestions[userIdColumn]?.type,
                          }),
                          offset: (currentPage - 1) * PROFILE_PAGE_SIZE,
                          limit: PROFILE_PAGE_SIZE,
                      }
                    : {
                          offset: (currentPage - 1) * PROFILE_PAGE_SIZE,
                          limit: PROFILE_PAGE_SIZE,
                      },
        };
    }, [
        projectUuid,
        searchString,
        currentPage,
        userIdColumn,
        fieldsWithSuggestions,
        getConditionalOperator,
    ]);
    const { data: profiles, isLoading: profilesLoading } =
        useGetProfiles(getProfileData);
    const { mutateAsync: mutateAsyncCount, isLoading: isCountLoading } =
        useAudienceCountByPayload({
            disableToasts: true,
        });

    const hasFetchedCount = useRef(false);

    const getCount = useCallback(async () => {
        if (!activeRelationUuid || !isSuccess || hasFetchedCount.current)
            return;
        hasFetchedCount.current = true;
        const response = await mutateAsyncCount({
            relationUuid: activeRelationUuid,
            payload: {},
        });
        setTotalCount(response.count);
    }, [activeRelationUuid, isSuccess, mutateAsyncCount]);

    useEffect(() => {
        if (activeRelationUuid && isSuccess && config) {
            void getCount();
        }
    }, [activeRelationUuid, isSuccess, config, getCount]);

    const pages = useMemo(() => {
        return {
            total: totalCount,
            lastPage: Math.ceil(totalCount / PROFILE_PAGE_SIZE),
            currentPage: currentPage,
            perPage: PROFILE_PAGE_SIZE,
            from: (currentPage - 1) * PROFILE_PAGE_SIZE,
            to: currentPage * PROFILE_PAGE_SIZE,
        };
    }, [totalCount, currentPage]);

    const isProfileCreated = useMemo(
        () => config && Object.values(config).length > 0,
        [config],
    );

    const filteredRelation = getTableRelation([
        JoinType.one_one,
        JoinType.many_one,
    ]);

    const fields = toFieldsWithSuggestions(
        filteredRelation,
        fieldsWithSuggestions,
    );
    const filterFields = useMemo(() => {
        const keysOfFields = Object.keys(fields);
        const filteredFields = config?.metricQuery?.dimensions
            .filter((field: string) =>
                keysOfFields.some((present_field) => present_field === field),
            )
            .filter((fieldId) => fieldId);
        return filteredFields;
    }, [fields, config]);
    const isPrimaryKeySelected = useMemo(() => {
        return filterFields?.includes(userIdColumn);
    }, [filterFields, userIdColumn]);
    const modifiedFields = useMemo(() => {
        return modifyFieldsWithSelectionStatus({
            fields: Object.values(fields),
            selectedFieldIds: filterFields ?? [],
            shouldDisableChecked: false,
            disabledFields: isPrimaryKeySelected ? [userIdColumn] : [],
        });
    }, [fields, filterFields, isPrimaryKeySelected, userIdColumn]);

    const handleSearchInput = (value: string) => {
        setSearchString(value);
        setCurrentPage(1);
    };
    const addingPrimaryKeyWhileCreatingProfile = useCallback(
        (items: FieldWithSuggestions[]) => {
            if (!userIdColumn) return [];
            const itemIds = items.map((item) => getItemId(item));
            if (itemIds.includes(userIdColumn)) {
                return itemIds;
            }
            return [...itemIds, userIdColumn].filter(Boolean);
        },
        [userIdColumn],
    );
    const handleProfileUpdate = useCallback(
        async (items: FieldWithSuggestions[]) => {
            if (!config) return;

            const selectedColumns = items.map((item) => getItemId(item));

            const newColumns =
                userIdColumn && !selectedColumns.includes(userIdColumn)
                    ? [userIdColumn, ...selectedColumns]
                    : selectedColumns;

            await updateProfile(
                updateProfilePayload({
                    payload: config,
                    newColumns,
                }),
            );

            setCurrentPage(1);
            close();
        },
        [config, updateProfile, close, userIdColumn],
    );
    if (activeProject && !isProjectSetUpCompleted(activeProject)) {
        return (
            <SetupPage
                description={t('set_up_page.description', {
                    type_description: t(
                        'set_up_page.type_description.profiles',
                    ),
                })}
            />
        );
    }

    if (isInitialLoading || profilesLoading) {
        return <SuboptimalState loading />;
    }

    return (
        <ManagerBuilderContainer
            withContentPadding={false}
            title={
                isProfileCreated && config ? (
                    <ProfileTitle
                        profile={config}
                        count={isCountLoading ? undefined : pages.total}
                    />
                ) : (
                    t('profiles')
                )
            }
        >
            <Box>
                {isProfileCreated ? (
                    <ProfileTable
                        isDataFetching={profilesLoading || updateLoading}
                        profiles={profiles?.rows ?? []}
                        rightSection={
                            canManageProfiles && (
                                <>
                                    <Button
                                        variant={ButtonVariant.OUTLINED}
                                        className={`
                                            hover:bg-opacity-10
                                            transition-all duration-200 
                                            border border-purple/20
                                            hover:border-purple
                                            group
                                            relative
                                            overflow-hidden
                                            ${
                                                updateWithAILoading
                                                    ? 'animate-pulse'
                                                    : ''
                                            }
                                        `}
                                        leftIcon={
                                            <Sparkle
                                                weight="duotone"
                                                color="rgb(var(--color-purple))"
                                                className="
                                                    group-hover:scale-110 
                                                    transition-transform 
                                                    duration-200
                                                    animate-pulse
                                                "
                                            />
                                        }
                                        disabled={updateWithAILoading}
                                        onClick={() => {
                                            updateProfileWithAI({
                                                columnsArray: [
                                                    ...Object.keys(fields),
                                                ],
                                                CustomMaterialisation: config
                                                    ? {
                                                          id: config.id,
                                                          name: config.name,
                                                          description:
                                                              config.description,
                                                          projectUuid,
                                                          relationId:
                                                              activeRelationUuid,
                                                          metricQuery:
                                                              config.metricQuery,
                                                          sqlQuery:
                                                              config.sqlQuery,
                                                          primaryKey:
                                                              config.primaryKey,
                                                      }
                                                    : {},
                                            });
                                        }}
                                    >
                                        <span className="relative z-10 flex items-center gap-2">
                                            {t(
                                                'profiles_manager.edit_columns_ai_button',
                                            )}
                                        </span>
                                        <span
                                            className="
                                            absolute inset-0 
                                            bg-gradient-to-r from-purple/5 to-transparent 
                                            transform -translate-x-full 
                                            group-hover:translate-x-0 
                                            transition-transform duration-500
                                        "
                                        />
                                    </Button>
                                    <ProfileManagerPropertySelect
                                        fields={modifiedFields}
                                        onSubmit={handleProfileUpdate}
                                        isLoading={updateLoading}
                                        isSuccess={updationSuccess}
                                        opened={opened}
                                        close={close}
                                        open={open}
                                        targetButton={
                                            <Button
                                                variant={ButtonVariant.OUTLINED}
                                                className={getButtonHighlightClassName(
                                                    opened,
                                                )}
                                                leftIcon={
                                                    <SquareSplitHorizontal
                                                        weight={'duotone'}
                                                        color={
                                                            opened
                                                                ? 'rgb(var(--color-blu-800))'
                                                                : 'rgb(var(--color-gray-800))'
                                                        }
                                                    />
                                                }
                                            >
                                                {t(
                                                    'profiles_manager.edit_columns_button',
                                                )}
                                            </Button>
                                        }
                                    />
                                </>
                            )
                        }
                        onPageChange={async (newPage) => {
                            setCurrentPage(newPage);
                        }}
                        onSearchChange={handleSearchInput}
                        pagination={pages}
                        fields={config?.metricQuery?.dimensions}
                        id={userIdColumn}
                        searchValue={searchString}
                        disablePagination={isCountLoading}
                    />
                ) : (
                    <Box className=" bg-gray-100/60">
                        <ProfileInitialCreateState
                            title={t(
                                'profiles_manager.create_initial_state_title',
                            )}
                            description={
                                canManageProfiles
                                    ? t(
                                          'profiles_manager.create_initial_state_description',
                                      )
                                    : t(
                                          'profiles_manager.cannot_create_profiles_text',
                                      )
                            }
                            buttonComponent={
                                canManageProfiles && (
                                    <ProfileManagerPropertySelect
                                        fields={modifyFieldsWithSelectionStatus(
                                            {
                                                fields: Object.values(fields),
                                                selectedFieldIds: [],
                                                shouldDisableChecked: false,
                                                disabledFields: [],
                                            },
                                        )}
                                        onSubmit={(items) => {
                                            createProfile(
                                                formatProfileData({
                                                    projectUuid,
                                                    relationUuid:
                                                        activeRelationUuid,
                                                    newColumns:
                                                        addingPrimaryKeyWhileCreatingProfile(
                                                            items,
                                                        ),
                                                }),
                                            );
                                        }}
                                        isLoading={createLoading}
                                        isSuccess={creationSuccess}
                                        opened={opened}
                                        close={close}
                                        open={open}
                                        targetButton={
                                            <Button
                                                variant={ButtonVariant.FILLED}
                                                leftIcon={
                                                    <Spiral color={'white'} />
                                                }
                                            >
                                                {t(
                                                    'audience_preview.modal_choose_properties',
                                                )}
                                            </Button>
                                        }
                                    />
                                )
                            }
                        />
                    </Box>
                )}
            </Box>
        </ManagerBuilderContainer>
    );
};
export default ProfileBuilderContainer;
